<template>
  <div>
    <base-select
      v-model="language"
      :items="$root.languages"
      placeholder="Language"
      :returnObject="false"
    ></base-select>
    <v-form v-model="valid" @submit.prevent="submitForm" ref="form">
      <div class="mt-10">
        <!-- upload image -->
        <v-row>
          <v-col cols="12" class="py-1 pb-2">
            <div class="upload-image">
              <img
                v-if="coverImage"
                :src="newCoverImage ? newCoverImage : coverImage.url"
                alt=""
              />
              <div class="upload-btn cursor-pointer">
                <v-icon dark color="grey">mdi-upload</v-icon>
                <span class="font-15 grey--text">Upload Image</span>
                <v-file-input
                  @change="handleCoverImage"
                  accept="image/*"
                  label="File input"
                  class="file-upload"
                  hide-details="true"
                ></v-file-input>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="12" class="py-1">
            <span class="font-26 font-500 d-block">Tags</span>
            <v-combobox
              clearable
              chips
              multiple
              label="Tags"
              hide-selected
              :items="displayedTags"
              v-model="selectedTags"
              :return-object="false"
              item-value="title"
              item-text="title"
              @update:search-input="searchTags"
              :rules="[(v) => !!v || 'this field is require']"
            ></v-combobox>
          </v-col>
          <v-col cols="12">
            <div class="b-bottom my-1"></div>
          </v-col>
          <!-- **** Main Info **** -->
          <v-col cols="12" class="mb-0 pt-0">
            <span class="font-26 font-500 d-block mb-5">Main Info</span>
            <v-row class="px-8">
              <!-- Trip  Name -->
              <v-col cols="12" md="6" class="py-1">
                <label class="grey--text font-12 font-500">Trip Name</label>
                <base-input v-model="obj.name[language]" placeholder="Trip  Name">
                </base-input>
              </v-col>

              <!-- LOCATION -->
              <v-col cols="12" md="6" class="py-1">
                <label class="grey--text font-12 font-500"> City name </label>
                <GmapAutocomplete
                  class="place-input"
                  ref="gMapAutocomplete"
                  :select-first-on-enter="true"
                  placeholder="City name"
                  @place_changed="setPlace"
                />
              </v-col>
              <v-col cols="12" md="12" class="py-1">
                <GmapMap
                  :clickable="true"
                  ref="maps"
                  :center="center"
                  :zoom="zoom"
                  map-type-id="roadmap"
                  style="width: 100%; height: 300px"
                  @click="addMarker"
                >
                  <GmapMarker
                    v-if="this.marker"
                    :position="{ lat: this.marker.lat, lng: this.marker.lng }"
                  />
                </GmapMap>
              </v-col>
              <!-- Title -->
              <v-col cols="12">
                <label class="grey--text font-12 font-500">Title</label>
                <base-textarea
                  :rules="[(v) => !!v || 'this field is require']"
                  v-model="obj.title[language]"
                  placeholder="What is this event about? Tell us more juicy details."
                ></base-textarea>
              </v-col>

              <!-- description -->
              <v-col cols="12">
                <label class="grey--text font-12 font-500">DESCRIPTION</label>
                <base-textarea
                  :rules="[(v) => !!v || 'this field is require']"
                  v-model="obj.description[language]"
                  placeholder="What is this event about? Tell us more juicy details."
                ></base-textarea>
              </v-col>
            </v-row>
          </v-col>

          <!-- Attraction Types -->
          <v-col cols="12" class="px-6">
            <label class="grey--text font-12 font-500 mb-4"
              >Attraction types (What's included)</label
            >
            <v-row
              v-for="(option, index) in obj.options"
              :key="index"
              class="mt-4"
            >
              <!-- delete -->
              <v-col cols="1" class="py-1">
                <div class="base-btn" @click="removeOptionsHandler(index)">
                  <v-icon color="error">mdi-minus-circle-outline</v-icon>
                </div>
              </v-col>

              <!-- text -->
              <v-col cols="11" class="py-1">
                <base-input
                  placeholder="Add a short headline"
                  v-model="option.description[language]"
                ></base-input>
              </v-col>
            </v-row>

            <!-- add new -->
            <div class="py-1 mt-2">
              <div class="base-btn" @click="addOptionsHandler">
                <v-icon color="grey">mdi-plus-circle-outline</v-icon>
              </div>
            </div>
          </v-col>

          <v-col cols="12">
            <div class="b-bottom my-1"></div>
          </v-col>
          <!-- **** Stats **** -->
          <v-col cols="12" class="mb-0 pt-0 pb-0">
            <span class="font-26 font-500 d-block">Stats</span>
          </v-col>
          <v-col cols="12" class="px-6">
            <v-row
              class="px-3 whitebg"
              :class="stats.length == 0 ? '' : 'py-2'"
            >
              <v-col
                cols="12"
                md="6"
                class="py-2"
                v-for="(stat, i) in stats"
                :key="i"
              >
                <v-checkbox
                  color="darkblue"
                  hide-details="true"
                  v-model="stat.checked"
                  :label="stat.name"
                  @change="stateChange(stat)"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" v-for="(stat, i) in statInputs" :key="i">
                <span class="grey--text font-12 font-500">{{ stat.name }}</span>
                <base-input
                  v-if="stat.checked && stat.unit"
                  v-model="stat.value"
                  :placeholder="stat.name"
                ></base-input>
                <base-select
                  v-if="stat.checked && !stat.unit"
                  :items="stat.selections"
                  itemText="value"
                  itemValue="_id"
                  v-model="stat.value"
                  :placeholder="stat.name"
                ></base-select>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <div class="b-bottom my-1"></div>
          </v-col>

          <!-- **** Good to know **** -->
          <v-col cols="12" class="mt-4 mb-0 pt-0 pb-0">
            <span class="font-26 font-500 d-block">Good to know</span>
          </v-col>

          <!-- what to tabs -->
          <v-col cols="12" class="px-6 py-0 mt-0">
            <div class="mt-4" v-for="(whatTo, i) in goodToKnow" :key="i">
              <span class="grey--text font-12 font-500 d-block mb-2">{{
                whatTo.title
              }}</span>
              <v-row v-for="(desc, j) in whatTo.desc" :key="j">
                <!-- delete -->
                <v-col cols="1" class="py-1">
                  <div class="base-btn" @click="removeWhatToHandler(i, j)">
                    <v-icon color="error">mdi-minus-circle-outline</v-icon>
                  </div>
                </v-col>

                <!-- text -->
                <v-col cols="11" class="py-1">
                  <base-input
                    placeholder="Add a short headline"
                    v-model="desc.value[language]"
                  ></base-input>
                </v-col>
              </v-row>

              <!-- add new -->
              <div class="py-1 mt-2">
                <div class="base-btn" @click="addWhatToHandler(i)">
                  <v-icon color="grey">mdi-plus-circle-outline</v-icon>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" class="mt-14">
            <!-- buttons -->
            <buttons-experience
              :isValid="valid"
              :key="valid"
              @cancel="$emit('backHandler')"
              @next="handleNext"
            ></buttons-experience>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </div>
</template>

<script>
import ButtonsExperience from "@/components/Buttons/ButtonsExperience.vue";
export default {
  props: {
    objItem: {
      type: Object,
      default: undefined
    },
    step: {
      type: Number,
      default: 0
    }
  },
  components: {
    ButtonsExperience
  },
  computed: {
    displayedTags() {
      return [...(this.tags || []), ...(this.selectedTags || [])];
    }
  },
  data: () => ({
    marker: undefined,
    center: { lng: 35.8984486, lat: 33.8786494 },
    zoom: 9.5,
    name: "",
    newCoverImage: "",
    valid: false,
    coverImage: {},
    obj: {
      name: {},
      title: {},
      description: {},
      country: "LB",
      location: {},
      options: [{ description: {}, included: true }],
      stats: []
    },
    dayOrHour: [
      { name: "Day", _id: 0 },
      { name: "Hour", _id: 1 }
    ],
    goodToKnow: [
      { title: "What To Bring", desc: [{ value: {} }] },
      { title: "What To Wear", desc: [{ value: {} }] },
      { title: "What To Expect", desc: [{ value: {} }] }
    ],
    maxHour: [
      (v) => !!v || "Required",
      (v) => v >= 0 || "",
      (v) => v <= 24 || ""
    ],

    maxMin: [
      (v) => !!v || "Required",
      (v) => v >= 0 || "",
      (v) => v <= 60 || ""
    ],
    files: [],
    filesUploaded: false,

    imagePreview: false,
    imagePreviewUrl: "",

    isEdit: false,
    keyChanged: false,
    keyMarkChange: false,

    stats: [],
    statInputs: [],
    tagSearchQuery: undefined,
    selectedTags: [],
    tags: [],
    language: "en"
  }),

  methods: {
    addOptionsHandler() {
      this.obj.options = [
        ...(this.obj.options || []),
        ...[{ description: {}, included: true }]
      ];
    },
    removeOptionsHandler(index) {
      this.obj.options = [...this.obj.options.filter((_, idx) => idx != index)];
    },
    handleCoverImage(file) {
      this.newCoverImage = URL.createObjectURL(file);
      this.coverImage = file;
      if (this.objItem) {
        this.obj.newCoverImage = URL.createObjectURL(file);
      }
    },
    fetchItemByID() {
      if (this.objItem) {
        this.obj = this.objItem;
        if (this.obj.location) {
          this.marker = {
            lng: Number(this.obj.location.long),
            lat: Number(this.obj.location.lat)
          };
          this.center = this.marker;
          this.zoom = 15;
          this.$refs.gMapAutocomplete.$el.value = this.obj.location.address;
        }

        this.coverImage = this.obj.coverImage;
        this.selectedTags = [...this.obj.tags].map((tag) => tag.title || tag);

        this.stats.forEach((stat) => {
          this.stateChange(stat);
        });

        this.goodToKnow[0].desc = this.obj.bring.map((val) => {
          return { value: val.label, _id: val._d };
        });
        this.goodToKnow[1].desc = this.obj.wear.map((val) => {
          return { value: val.label, _id: val._d };
        });
        this.goodToKnow[2].desc = this.obj.expect.map((val) => {
          return { value: val.label, _id: val._d };
        });
      }
    },
    handleNext() {
      // start of validation
      if (!this.obj.location.long || !this.obj.location.lat) {
        this.$store.dispatch("showSnack", {
          text: "Location Marker is Missing",
          color: "error"
        });
        return;
      }
      if (!this.coverImage) {
        this.$store.dispatch("showSnack", {
          text: "cover image is required",
          color: `error`
        });
        return;
      }
      this.$refs.form.validate();
      if (!this.valid) {
        setTimeout(() => {
          document
            .querySelector(".v-input.error--text:first-of-type")
            .scrollIntoView({
              behavior: "smooth"
            });
        }, 100);
        return;
      }

      // handle stats
      if (this.stats.length > 0) {
        // get only checked stats
        this.obj.stats = [...this.statInputs.filter((stat) => stat.checked)];
        this.obj.stats = [
          ...this.statInputs.map((stat) => {
            if (stat.inputType == "1") {
              return {
                statType: this.objItem
                  ? stat.statType._id
                    ? stat.statType._id
                    : stat.statType
                  : stat._id,
                value: stat.value._id || stat.value,
                unit: stat.unit
              };
            } else {
              return {
                statType: this.objItem
                  ? stat.statType._id
                    ? stat.statType._id
                    : stat.statType
                  : stat._id,
                value: stat.value._id || stat.value,
                unit: null
              };
            }
          })
        ];
      }

      this.obj.bring = this.goodToKnow[0].desc.map((val) => {
        return { label: val.value };
      });
      this.obj.wear = this.goodToKnow[1].desc.map((val) => {
        return { label: val.value };
      });
      this.obj.expect = this.goodToKnow[2].desc.map((val) => {
        return { label: val.value };
      });

      this.obj.tags = this.selectedTags;

      this.$emit("nextHandler", {
        obj: this.obj,
        coverImage: this.coverImage
      });
      this.$emit("dayValue", this.obj.dayOrHour == 1 ? 1 : this.obj.duration);
    },
    setPlace(event) {
      if (event.geometry && event.geometry.location) {
        this.marker = {
          lat: event.geometry.location.lat(),
          lng: event.geometry.location.lng()
        };
        this.center = event.geometry.location;
        this.zoom = 15;
        this.obj.location.long = this.marker.lng;
        this.obj.location.lat = this.marker.lat;
        this.obj.location.address = this.$refs.gMapAutocomplete.$el.value;
      }
    },
    addMarker(event) {
      this.marker = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      };
      this.obj.location.long = this.marker.lng;
      this.obj.location.lat = this.marker.lat;
      this.obj.location.address = this.$refs.gMapAutocomplete.$el.value;
    },
    stateChange(stat) {
      if (stat.checked) {
        let foundStat = this.statInputs.find(
          (statInput) => statInput.name == stat.name
        );

        if (!foundStat) {
          this.statInputs.push(stat);
        }
      } else {
        this.statInputs = this.statInputs.filter(
          (statInput) => statInput.name != stat.name
        );
      }
    },
    async searchTags(searchWord) {
      let { data } = await this.$axios.get(
        `tag?searchWord=${searchWord || ""}&pageSize=20`
      );
      if (data) {
        this.tags = data.tags.map((tag) => tag.title);
      }
    },
    async fetchStats() {
      let { data } = await this.$axios.get(`activityStats`);
      if (data) {
        this.stats = data.stats;
        this.stats = this.objItem
          ? [
              ...data.stats.map((stat) => {
                let objStat = this.objItem.stats.find((s) => {
                  if (s.statType) {
                    return s.statType._id == stat._id;
                  } else {
                    return s._id == stat._id;
                  }
                });
                if (objStat) {
                  return {
                    ...stat,
                    value: objStat.value,
                    checked: true,
                    statType: stat._id
                  };
                } else {
                  return { ...stat, checked: false, statType: stat._id };
                }
              })
            ]
          : [
              ...data.stats.map((stat) => {
                return { ...stat, checked: false };
              })
            ];
      }
    },
    addWhatToHandler(i) {
      this.goodToKnow[i].desc.push({value: {}});
    },
    removeWhatToHandler(i, j) {
      this.goodToKnow[i].desc = [
        ...this.goodToKnow[i].desc.filter((_, idx) => idx != j)
      ];
    }
  },
  async created() {
    this.language = this.$root.language;
    this.obj.country = this.$root.country.short;
    await this.fetchStats();
    if (this.objItem && this.objItem._id) {
      await this.fetchItemByID();
    }
  }
};
</script>

<style scoped>
.place-input {
  height: 36px;
  line-height: 35px;
  border: 1px solid #e2e5ed;
  border-radius: 5px;
  width: 100%;
  padding-left: 10px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
}
</style>
