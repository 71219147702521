<template>
  <div class="mt-5">
    <base-select
      v-model="language"
      :items="$root.languages"
      placeholder="Language"
      :returnObject="false"
    ></base-select>
    <v-form v-model="valid" ref="stepForm">
      <v-tabs v-model="selectedDay" centered class="mb-2" @change="dayChanged">
        <v-tabs-slider color="secondary"></v-tabs-slider>

        <v-tab v-for="(day, i) in obj.schedules" :key="i" class="tab-container">
          <div
            v-if="obj.schedules.length > 1"
            class="delete-btn"
            @click="deleteDayHandler(i, $event)"
          >
            <v-icon color="red">mdi-delete</v-icon>
          </div>
          Day {{ i + 1 }}
        </v-tab>

        <v-icon
          color="white"
          class="secondary rounded py-1 px-6 ml-3"
          @click="addDay"
          >mdi-plus-circle-outline</v-icon
        >
      </v-tabs>

      <v-tabs-items v-model="selectedDay">
        <v-tab-item
          v-for="(day, index) in obj.schedules"
          :key="index"
          class="attractions pa-5"
        >
          <div
            v-for="(schedule, index) in day.day.schedule"
            :key="index"
            :style="
              index % 2
                ? 'backgroundColor:rgb(245, 245, 245)'
                : 'backgroundColor:white'
            "
          >
            <v-row class="mb-0 mt-0 mx-auto">
              <v-col cols="12" class="py-0 mb-0 d-flex">
                <div
                  class="d-block mr-1 my-auto"
                  style="width: 30px; height: 30px"
                >
                  <div
                    class="cursor-pointer"
                    v-if="day.day.schedule.length > 1"
                    @click="deleteAttractionHandler(index, $event)"
                  >
                    <v-icon color="red">mdi-delete</v-icon>
                  </div>
                </div>
                <div class="w-100">
                  <label class="grey--text font-12 font-500">Attraction</label>
                  <v-autocomplete
                    v-model="schedule.attraction"
                    :items="displayedAttractions(schedule)"
                    outlined
                    dense
                    :filter="() => true"
                    label="Search for attractions"
                    menu-props="auto"
                    :return-object="true"
                    @update:search-input="searchAttraction"
                    :rules="[(v) => !!v || v >= 0 || '']"
                    :value-comparator="(a, b) => a?._id == b?._id"
                    class="ma-0"
                  >
                    <template v-slot:item="{ item }">
                      <div class="d-flex mt-3">
                        <div
                          class="mr-2 d-block rounded-circle"
                          style="width: 24px; height: 24px; overflow: hidden"
                        >
                          <img
                            v-if="item.coverImage"
                            :src="item.coverImage.url"
                            alt=""
                            width="24"
                            height="24"
                          />
                        </div>
                        <p class="d-block">
                          {{ item.name[language] || item.name }}
                        </p>
                      </div>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-chip
                        class="ma-2"
                        close
                        @click:close="schedule.attraction = undefined"
                      >
                        <div class="d-flex mt-3">
                          <div
                            class="mr-2 d-block rounded-circle"
                            style="width: 24px; height: 24px; overflow: hidden"
                            v-if="item.coverImage && item.coverImage.url"
                          >
                            <img
                              class=""
                              :src="item.coverImage && item.coverImage.url"
                              alt=""
                              width="24"
                              height="24"
                            />
                          </div>
                          <p class="d-block">
                            {{ item.name[language] || item.name }}
                          </p>
                        </div>
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </div>
              </v-col>
            </v-row>
            <v-row
              class="mt-0 mb-4 mx-auto pr-8"
              :key="shouldReloadDescriptions"
            >
              <v-checkbox
                class="font-12 ml-10"
                v-model="schedule.addDescription"
                label="Add Description"
                @change="addDescriptionChanged"
              />
              <v-col
                cols="12"
                class="pt-0 pb-0 mb-3 mt-0 ml-8"
                v-if="schedule.addDescription"
              >
                <label class="grey--text font-12 font-500"
                  >DESCRIPTION (Optional)</label
                >
                <base-textarea
                  sizeWord="250"
                  v-model="schedule.description[language]"
                  :rules="[]"
                ></base-textarea>
              </v-col>
            </v-row>
          </div>
          <v-col cols="12" justify="center" class="text-center">
            <div class="base-btn w-100 secondary--text" @click="addAttraction">
              <v-icon color="secondary">mdi-plus-circle-outline</v-icon>
              <p class="pa-0 ma-0">Add Attraction</p>
            </div>
          </v-col>
        </v-tab-item>
      </v-tabs-items>
    </v-form>

    <div class="mt-14">
      <buttons-experience
        :isValid="valid"
        :key="valid"
        @cancel="$emit('backHandler')"
        @next="nextHandler"
        text="Submit"
        cancelText="Cancel"
      ></buttons-experience>
    </div>

    <v-dialog v-model="deleteDayDialog" width="300" hide-overlay>
      <delete-confirm
        :key="deleteDayDialog"
        @confirmAccess="deleteDay"
        @closeDialog="deleteDayDialog = false"
      ></delete-confirm>
    </v-dialog>
    <v-dialog v-model="deleteAttractionDialog" width="300" hide-overlay>
      <delete-confirm
        :key="deleteAttractionDialog"
        @confirmAccess="deleteAttraction"
        @closeDialog="deleteAttractionDialog = false"
      ></delete-confirm>
    </v-dialog>
  </div>
</template>

<script>
import ButtonsExperience from "@/components/Buttons/ButtonsExperience.vue";
export default {
  props: {
    objItem: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    ButtonsExperience
  },
  data: () => ({
    valid: false,
    shouldReloadDescriptions: false,
    obj: {
      schedules: [
        { day: { schedule: [{ addDescription: false, description: {} }] } }
      ]
    },
    selectedDay: "",
    attractions: [],
    attractionSearch: "",
    deleteDayDialog: false,
    selectedDayToDelete: undefined,
    deleteAttractionDialog: false,
    selectedAttractionToDelete: undefined,
    attractionSearchQuery: undefined,
    language: "en"
  }),
  methods: {
    displayedAttractions(schedule) {
      return [
        ...this.attractions,
        ...[schedule.attraction].filter((item) => item)
      ]
        .filter(
          (value, index, self) =>
            self.findIndex((item) => item._id === value._id) === index
        )
        .map((item) => {
          return {
            _id: item._id,
            name: item.name,
            coverImage: item.coverImage,
            location: item.location
          };
        });
    },
    addDescriptionChanged() {
      this.shouldReloadDescriptions = !this.shouldReloadDescriptions;
    },
    dayChanged(index) {
      this.selectedDay = index;
    },
    addDay() {
      this.obj.schedules.push({ day: { schedule: [{}] } });
    },
    addAttraction() {
      this.obj.schedules[this.selectedDay].day.schedule.push({});
    },
    deleteDay() {
      this.obj.schedules.splice(this.selectedDayToDelete, 1);
      this.deleteDayDialog = false;
    },
    deleteDayHandler(index, event) {
      event.stopPropagation();
      this.selectedDayToDelete = index;
      this.deleteDayDialog = true;
    },
    deleteAttraction() {
      this.obj.schedules[this.selectedDay].day.schedule.splice(
        this.selectedAttractionToDelete,
        1
      );
      this.deleteAttractionDialog = false;
    },
    deleteAttractionHandler(index, event) {
      event.stopPropagation();
      this.selectedAttractionToDelete = index;
      this.deleteAttractionDialog = true;
    },
    async nextHandler() {
      this.$refs.stepForm.validate();
      let isValid =
        this.obj.schedules.length &&
        !this.obj.schedules.some(
          (schedule) => schedule.day.schedule.length == 0
        );
      if (!isValid) {
        setTimeout(() => {
          document.querySelector(".v-input.error--text:first-of-type");
        }, 100);
        this.$store.dispatch("showSnack", {
          text: "please check all fields",
          color: `error`
        });
        return;
      }
      this.obj.schedules.forEach((schedule, index) => {
        schedule.day.dayNumber = index + 1;
        schedule.day.schedule = schedule.day.schedule.map((sch) => {
          const location = sch.attraction?.location;
          return {
            description: sch.description,
            transport: "walk",
            location: location?.address || sch.location,
            latitude: location?.lat
              ? Number(location.lat)
              : Number(sch.latitude),
            longitude: location?.long
              ? Number(location.long)
              : Number(sch.longitude),
            guided: false,
            free: true,
            attraction: sch.attraction._id,
            price: "0"
          };
        });
      });

      this.obj.duration = this.obj.schedules.length;
      this.obj.dayOrHour = 1;
      this.obj.price = 0;
      this.$emit("nextHandler", this.obj);
    },
    async searchAttraction(event) {
      let { data } = await this.$axios.get(
        `miniguide/all/new?${
          (event && event.length) > 0 ? "searchWord=" + event : ""
        }&pageSize=20&pageNumber=1`
      );
      if (data) {
        this.attractions = data.data;
      }
    }
  },
  async addNewDay() {},
  async created() {
    this.language = this.$root.language;
    if (this.objItem && this.objItem._id) {
      this.obj = this.objItem;
      this.obj.schedules.forEach((schedule) => {
        schedule.day.schedule.forEach((sch) => {
          sch.addDescription = false;
          this.$root.languages.forEach((language) => {
            if (!sch.addDescription) {
              sch.addDescription =
                sch.description[language] !== undefined &&
                sch.description[language] !== "" &&
                sch.description[language] !== null;
            }
          });
        });
      });
    }
    await this.searchAttraction();
  }
};
</script>

<style lang="scss" scoped>
.tab-container {
  position: relative;
}

.delete-btn {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  cursor: pointer;
}

.tab-container:hover .delete-btn {
  display: block;
}
.attractions {
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.line {
  width: 100%;
  height: 1px;
}
</style>
