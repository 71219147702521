<template>
  <div class="trips">
    <v-row class="ma-0 pa-0">
      <v-col
        cols="12"
        :md="paramID ? 6 : 12"
        v-if="!$vuetify.breakpoint.mobile || !paramID"
      >
        <list-items
          :listItems="listItems"
          @paginationHandler="fetchList"
          :total="total"
          :districts="districts"
          :loading="loading"
          @searchTrip="searchTrip"
          @reloadData="reloadData"
          @addTrip="addTrip"
          :md="paramID ? 6 : 3"
          @itemClicked="itemClicked"
        ></list-items>
      </v-col>
      <v-col cols="12" :md="6">
        <item-body
          v-if="itemID && itemID._id"
          :item="{ data: itemID }"
          @editTrip="editTrip"
          @reloadData="reloadData"
          @collapse="collapse"
        />
      </v-col>
    </v-row>
    <v-dialog v-model="addTripDialog" max-width="750px">
      <new-trip-dialog
        :key="addTripDialog"
        @close="addTripDialog = false"
        @reloadData="reloadData"
      ></new-trip-dialog>
    </v-dialog>
    <v-dialog v-model="editTripDialog" max-width="750px">
      <new-trip-dialog
        v-if="itemID"
        :key="editTripDialog"
        @close="editTripDialog = false"
        @reloadData="reloadData"
        :item="itemID"
        :isEdit="true"
      ></new-trip-dialog>
    </v-dialog>
  </div>
</template>

<script>
import ListItems from "@/components/trips/ListItems";
import ItemBody from "@/components/trips/ItemBody";
import NewTripDialog from "@/components/dialogs/Library/Trip/Index.vue";
export default {
  components: {
    ListItems,
    ItemBody,
    NewTripDialog
  },
  data: () => ({
    page: 1,
    paramID: "",
    listItems: [],
    itemID: undefined,
    total: 0,
    districts: [],
    loading: true,
    searchWord: undefined,
    editTripDialog: false,
    addTripDialog: false
  }),
  watch: {
    "$route.params": {
      async handler() {
        this.paramID = this.$route.params.id;
        await this.fetchItemByID();
      }
    }
  },
  methods: {
    reloadData() {
      this.fetchList(this.page);
      this.fetchItemByID();
    },
    addTrip() {
      this.addTripDialog = true;
    },
    editTrip() {
      this.editTripDialog = true;
    },
    async searchTrip(searchWord) {
      this.listItems = [];
      this.page = 1;
      this.searchWord = searchWord;
      await this.fetchList();
      if (!this.$vuetify.breakpoint.mobile) {
        if (!searchWord || searchWord.length == 0) {
          this.paramID = this.$route.params.id;
          this.fetchItemByID();
        } else if (this.listItems.length > 0) {
          this.paramID = this.listItems[0]._id;
          this.fetchItemByID();
        } else {
          this.paramID = undefined;
          this.itemID = undefined;
        }
      }
    },
    async fetchList(page) {
      this.loading = true;
      if (this.source) {
        this.source.cancel();
      }
      this.source = this.$axios.CancelToken.source();
      const currentSource = this.source;
      if (!page) {
        page = 1;
      }
      this.page = page;
      let { data } = await this.$axios.get(
        `/library-activity?${
          (this.searchWord && this.searchWord.length) > 0
            ? "searchWord=" + this.searchWord + "&"
            : ""
        }pageSize=12&pageNumber=${page}`,
        { cancelToken: this.source.token }
      );
      if (data) {
        this.listItems = data.data;
        this.total = data.count;
      }
      if (currentSource === this.source) {
        this.loading = false;
      }
    },
    async fetchItemByID() {
      if (this.paramID) {
        let { data } = await this.$axios.get(`/library-activity/${this.paramID}`);

        if (data && data.data) {
          this.itemID = data.data;
        }
      }
    },
    collapse() {
      this.$router.push("/library/trips");
      this.paramID = undefined;
    },
    itemClicked(item) {
      this.$router.push(`/library/trips/${item._id}`);
    }
  },
  async created() {
    this.paramID = this.$route.params.id;
    await this.fetchList();
    await this.fetchItemByID();
  }
};
</script>

<style lang="scss" scoped>
.trips {
  background: white;
  height: 100vh;
  overflow: hidden;
}
</style>
