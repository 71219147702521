<template>
  <div class="white pa-5 py-3 trip-dialog">
    <main-section
      v-if="step == 1"
      key="1"
      @nextHandler="stepOneHandler"
      :objItem="obj"
      @backHandler="$emit('close')"
    ></main-section>
    <steps-section
      v-if="step == 2"
      key="2"
      @nextHandler="submitHandler"
      :objItem="obj"
      @backHandler="$emit('close')"
    ></steps-section>
  </div>
</template>

<script>
import MainSection from "./components/Main";
import StepsSection from "./components/Steps";
export default {
  components: {
    MainSection,
    StepsSection,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    step: 0,
    obj: undefined,
    dayValue: {},
    coverImage: {},
    newCoverImage: "",
    images: [],
    request: "",
  }),
  watch: {
    $route: {
      handler() {
        this.step = 1;
      },
    },
  },
  methods: {
    stepOneHandler(val) {
      this.step = 2;
      this.obj = { ...val.obj };
      this.coverImage = val.coverImage;
      this.newCoverImage = val.obj.newCoverImage;
    },
    async submitHandler(val) {
      this.obj = { ...this.obj, ...val };
      const tags = await this.$axios.post(`tag/multiple`, {
        tags: this.obj.tags,
      });
      this.obj.tags = tags.data.tags.map((tag) => tag._id);
      if (!this.item) {
        const response = await this.$axios.post("library-activity", this.obj);
        if (response.data) {
          // send cover image
          const id = response.data._id || response.data.data._id;
          let formDataMedia = new FormData();
          formDataMedia.append("activityId", id);
          formDataMedia.append("coverImage", this.coverImage);
          // request to upload media
          await this.$axios.post(
            `library-activity/media/cover`,
            formDataMedia,
            {
              "content-type": "multipart/form-data",
            }
          );
        }
        if (response.data) {
          this.$store.dispatch("showSnack", {
            text: "Added succesfully",
            color: `success`,
          });
          this.$emit("reloadData");
          this.$emit("close");
        } else {
          this.$store.dispatch("showSnack", {
            text: "Something went wrong",
            color: `error`,
          });
        }
      } else {
        const response = await this.$axios.put(
          `library-activity/${this.item._id}`,
          this.obj
        );
        if (this.newCoverImage) {
          // send cover image

          let formDataMedia = new FormData();
          formDataMedia.append("activityId", this.item._id);
          formDataMedia.append("coverImage", this.coverImage);
          // request to upload media

          await this.$axios.post(
            `library-activity/media/cover`,
            formDataMedia,
            {
              "content-type": "multipart/form-data",
            }
          );
        }
        if (response.data) {
          this.$store.dispatch("showSnack", {
            text: "Edited succesfully",
            color: `success`,
          });
          this.$emit("reloadData");
          this.$emit("close");
        } else {
          this.$store.dispatch("showSnack", {
            text: "Something went wrong",
            color: `error`,
          });
        }
      }
    },
    async fetchItemById() {
      if (this.item) {
        let { data } = await this.$axios.get(
          `/library-activity/${this.item._id}`
        );

        if (data) {
          this.obj = data.data;
        }
      }
    },
  },
  async created() {
    await this.fetchItemById();
    this.step = 1;
  },
};
</script>
